<template>
  <div class="login" v-cloak>
    <div class="login-main">
      <el-image class="main-left" :src="require(`../../assets/image/loginLeft.png`)" fit="contain"></el-image>
      <div class="main-right">
        <div class="right-center">
          <div class="login-title">
            <div class="title-welcome">欢迎使用</div>
            <div class="title-ai">大嘴鸟AI</div>
          </div>
          <div class="login-form">
            <el-form ref="loginForm" :model="loginForm" :rules="rules">
              <div class="form-title">手机号</div>
              <el-form-item prop="phone">
                <el-input type="text" v-model="loginForm.phone" placeholder="账号" />
              </el-form-item>
              <div class="form-title">密码</div>
              <el-form-item prop="password">
                <el-input type="password" v-model="loginForm.password" placeholder="请输入密码" show-password />
              </el-form-item>
              <el-form-item>
                <el-button class="do-login" type="primary" @click="doLogin">登录</el-button>
              </el-form-item>
              <div class="form-bottom">没有账号？<span class="register" @click="gotoRegister">注册</span></div>
            </el-form>
          </div>
        </div>
        <a class="login-bottom" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备19022148号</a>
      </div>
    </div>
  </div>
</template>

<script>
  import { aiLogin } from '../../request/api'
  import { mapMutations } from 'vuex'
  
  export default {
    data() {
      return {
        loginForm: {
          phone: '',
          password: '',
          remember: false
        },
        rules: {
          phone: [
            { required: true, message: '请输入账号', trigger: 'blur' },
            { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号码', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      async doLogin() {
        this.$refs.loginForm.validate(async (valid) => {
          if (valid) {
            try {
              const res = await aiLogin(this.loginForm)
              if (res.status !== 200 || res.data.code !== '1000') {
                this.$message.error(res.data.msg)
                return
              }
              this.setUser(res.data.data)
              localStorage.setItem('usersinfomation', JSON.stringify(res.data.data))
              this.$router.push('/')
            } catch (error) {
              this.$message.error('登录请求失败');
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      gotoRegister() {
        this.$router.push('/aiRegister')
      },
      ...mapMutations('m_login', ['setUser'])
    }
  }
</script>

<style lang="less" scoped>
[v-cloak] {
  display: none;
}
.login {
  height: 100%;
  background-image: url(../../assets/image/loginBackground.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-main {
    height: 640px;
    width: 1100px;
    background-color: #fff;
    border-radius: 30px;
    display: flex;
    .main-left {
      height: 640px;
    }
    .main-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .login-bottom {
        color: #808b94;
        font-size: 18px;
      }
      .right-center {
        height: 500px;
        width: 400px;
        display: flex;
        flex-direction: column;
        .login-form {
          height: 400px;
          .do-login {
            width: 150px;
            border-radius: 20px;
            background-color: #4b71ff;
            font-size: 18px;
            &:hover {
              background-color: #6586fc;
            }
          }
          .form-bottom {
            color: #808b94;
            font-size: 14px;
            .register {
              border-bottom: 1px solid #808b94;
              cursor: pointer;
              &:hover {
                color: #4b71ff;
              }
            }
          }
          .form-title {
            margin-bottom: 10px;
            font-size: 18px;
            color: #808b94;
          }
        }
        .login-title {
          height: 100px;
          .title-welcome {
            font-size: 18px;
            color: #808b94;
            margin-bottom: 4px;
            font-weight: bold;
          }
          .title-ai {
            font-size: 30px;
            color: #4b71ff;
          }
        }
      }
    }
  }
}
</style>